


.tl-container {
  /*    border: 4px dotted red; 
   */
    padding-top: 5em;  
    margin-top: 1em;
    margin-left: 10vw;
    margin-right: 10vw;
    margin-bottom: 5em;
  
    /* DISABLE TEXT SELECT */
    -webkit-user-select: none;  /* Chrome all / Safari all */
    -moz-user-select: none;     /* Firefox all */
    -ms-user-select: none;      /* IE 10+ */
    user-select: none;          /* Likely future */ 
  
  
  /*   -webkit-mask: linear-gradient(black 0 0) left, linear-gradient(to right, black, transparent) right;
    -webkit-mask-size:calc(100% - 80px) 100%, 80px 100%;
    -webkit-mask-repeat: no-repeat; */
    -webkit-mask: linear-gradient(to right, black calc(99% - 80px), transparent);

  }


.rct-sidebar  {
    background: #1c1c1c !important;
    color: #ffffff !important;
    display: flex;
    font-size: 17px;
    justify-content: space-evenly;
    align-items: center;
    margin: 0;
    border-right: unset !important;
    margin-left: '5px';
}

.rct-sidebar-row {
  border-bottom: 1px solid #1a1a1a !important;
}

.timeline-left-header {
  background: #1c1c1c !important;
  color: #ffffff !important;
  display: flex;
  margin: 0;
  font-size: 20px;
  height: 100%;
  justify-content: space-evenly;
  align-items: center;

}



.react-calendar-timeline .rct-dateHeader {
  border-bottom: 1px solid #1a1a1a !important;
  background-color: #1c1c1c !important;
  border-left: 2px solid #1a1a1a !important;
  color: #fff !important;
}

.react-calendar-timeline .rct-header-root {
  border-bottom: unset !important;
  background-color: #1a1a1a !important;
}

.react-calendar-timeline .rct-calendar-header {
  border: 1px solid #1a1a1a !important;
}


.react-calendar-timeline .rct-horizontal-lines .rct-hl-odd  {
  border-bottom: 1px solid #1a1a1a !important;
}

.react-calendar-timeline .rct-horizontal-lines .rct-hl-even {
  border-bottom: 1px solid #1a1a1a !important;

}

.react-calendar-timeline .rct-vertical-lines .rct-vl {
  border-left: 1px solid #1a1a1a !important;
}

.react-calendar-timeline .rct-vertical-lines .rct-vl.rct-day-6, .react-calendar-timeline .rct-vertical-lines .rct-vl.rct-day-0 {
  background: #3e242473 !important;
}



/* TOOLTIP */
.tooltip-base {
  border-radius: 12px !important;
}

.tooltip-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tooltip-header {
  margin: 0;
  margin-bottom: 0px;
  font-size: 17px;
}
.tooltip-row {
  display: flex;
  flex-direction: row;
}

.tooltip-row .row {
}

.tooltip-data {
  margin: 0;
  margin-top: 5px;
  font-size: 14px;
}

/* TOOLTIP END */