.background-image {
    position: fixed;
    left: 0;
    right: 0;
    z-index: 0;
    display: block;
    background-image: url('../../images/login_background.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100vw;
    height: 100vh;
    -webkit-filter: blur(2px);
    -moz-filter: blur(2px);
    -o-filter: blur(2px);
    -ms-filter: blur(2px);
    filter: blur(2px);
  }